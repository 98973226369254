<template>
  <div class="page logs">
    <b-overlay
      id="authenticating-overlay"
      no-wrap
      :show="loading"
      variant="light"
      opacity="0.5"
      rounded="false"
    />
    <div
      v-if="routeFrom"
      class="back-link"
      @click="$router.push({
        name: routeFrom.name,
        params: {
          ...routeFrom.params
        }
      })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span v-if="routeFrom.name === 'DatasetDetail' && currentDataset && currentDataset.display_name">
        {{ currentDataset.display_name }}
      </span>
      <span v-else-if="routeFrom.name === 'DatasetDetail'">
        {{ capitalizeFirstLetter($tc('words.dataset', 1)) }}
      </span>
      <span v-else-if="routeFrom.name === 'UserDetail'">
        {{ capitalizeFirstLetter($tc('words.user', 1)) }}
      </span>
    </div>
    <div class="logs-container">
      <h2>
        {{ $t('words.activityFlow') }}
      </h2>
      <LogItem
        v-for="log of logs"
        :key="`log-${log.id}`"
        :log="log"
      />
    </div>
    <Pagination
      v-if="count >= logsPerPage"
      :nb-pages="Math.ceil(count / logsPerPage)"
      v-on="$listeners"
      @change-page="changePage"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dashboardAPI from '@/api/dashboardAPI.js';

import LogItem from '@/components/Logs/LogItem.vue';
import Pagination from '@/components/SearchablePaginatedTable/Pagination.vue';

export default {
  name: 'LogView',

  components: {
    LogItem,
    Pagination
  },

  data() {
    return {
      routeFrom: null,
      loading: false,
      logs: [],
      count: 0,
      currentPage: 1
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset'
    ]),

    logsPerPage() {
      if (this.$config.logs && this.$config.logs.itemsPerPage) {
        return this.$config.logs.itemsPerPage;
      }
      return 50;
    }
  },

  watch: {
    '$i18n.locale': function(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.getLogs(this.currentPage);
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = from;
    });
  },

  created() {
    this.getLogs();
  },

  methods: {
    async getLogs(page = 1) {
      try {
        this.loading = true;
        const response = await dashboardAPI.getDatasetLogs(this.$route.params.id, this.$route.params.object, page);
        this.logs = response.results;
        this.count = response.count;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    changePage(e) {
      this.currentPage = e;
      this.getLogs(e);
    }
  }
};
</script>

<style scoped lang="less">
.logs {
  .logs-container {
    padding: 0 3rem;
    h2 {
      text-align: center;
      color: #424C55;
    }
  }
}
</style>