import { render, staticRenderFns } from "./LogsView.vue?vue&type=template&id=2ab26836&scoped=true"
import script from "./LogsView.vue?vue&type=script&lang=js"
export * from "./LogsView.vue?vue&type=script&lang=js"
import style0 from "./LogsView.vue?vue&type=style&index=0&id=2ab26836&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab26836",
  null
  
)

export default component.exports