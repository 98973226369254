var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"log_item"},[_c('div',{staticClass:"log_item-icon",style:({
      'background-color': _vm.actions[_vm.log.action.value].color
    })},[_c('i',{class:("bi bi-" + (_vm.actions[_vm.log.action.value].icon))})]),(_vm.log.user)?_c('img',{staticClass:"log_item-avatar",attrs:{"src":_vm.avatarUrl}}):_c('div',{staticClass:"log_item-avatar"}),_c('div',{staticClass:"log_item-content"},[_c('div',{staticClass:"log_item-content-description"},[_vm._v(" "+_vm._s(_vm.log.label)+" ")]),_c('div',{staticClass:"log_item-content-signature"},[(_vm.log.user)?_c('span',{staticClass:"log_item-content-signature-user"},[_vm._v(" Par "),_c('a',{on:{"click":function($event){return _vm.$router.push({
            name: 'UserDetail',
            params: {
              id: _vm.log.user.id
            }
          })}}},[_vm._v(" "+_vm._s(_vm.log.user.label)+" ")]),_vm._v(" , ")]):_vm._e(),(_vm.$i18n.locale === 'en')?_c('span',{staticClass:"log_item-content-signature-date",on:{"mouseenter":function($event){_vm.isDateTooltipVisible = true},"mouseleave":function($event){_vm.isDateTooltipVisible = false}}},[_vm._v(" "+_vm._s(_vm.timeSinceEn(new Date(_vm.log.action_time)))+" ago ")]):_c('span',{staticClass:"log_item-content-signature-date",on:{"mouseenter":function($event){_vm.isDateTooltipVisible = true},"mouseleave":function($event){_vm.isDateTooltipVisible = false}}},[_vm._v(" il y a "+_vm._s(_vm.timeSinceFr(new Date(_vm.log.action_time)))+" ")]),_c('div',{staticClass:"date_tooltip",class:{ visible: _vm.isDateTooltipVisible }},[_vm._v(" "+_vm._s(new Date(_vm.log.action_time).toLocaleDateString( _vm.$i18n.locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', }))+" "+_vm._s(new Date(_vm.log.action_time).toLocaleTimeString())+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }